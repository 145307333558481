/**
 * @Author: zhang
 * @Date:   2021-01-25 13:12:03
 * @Last Modified by:   zhang
 * @Last Modified time: 2021-04-08 16:10:06
 */
import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import md5 from 'js-md5';
// import Vuex from 'vuex'
import store from './store'
// index 用户 个人
import { users } from "@/components/index"
Vue.prototype.$user = users;
//  打开新页面
import { open } from '@/skip/skip'

import axios from 'axios';
// import api from '@/request/api.js' // 导入api接口

// Vue.prototype.$api = api; // 将api挂载到vue的原型上复制代码
let token = localStorage.getItem('lzyyToken');
if (token != null) {
  Vue.prototype.$token = token;
  Vue.prototype.$tokens = token;
  // Vue.prototype.imageUrl =  "http://hlwyaodian.jzbwlkj.com/api/file/upload?token=" + token;
  Vue.prototype.imageUrl = "https://www.fu-nuo.com/api/file/upload?token=" + token;
}
// Vue.prototype.api = 'http://hlwyaodian.jzbwlkj.com'
Vue.prototype.api = 'https://www.fu-nuo.com'
Vue.prototype.$open = open

Vue.prototype.$axios = axios
Vue.prototype.$md5 = md5

Vue.config.productionTip = false

Vue.use(ElementUI);

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
