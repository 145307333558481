export default [
    {
        path: "/personal/doctor/index",
        name: "医生个人中心",
        component: () => import('@/views/personal/doctor/index.vue')
    },
    {
        path: "/personal/expert/index",
        name: "专家个人中心",
        component: () => import('@/views/personal/expert/index.vue')
    },
    {
        path: "/personal/deployment/index",
        name: "调配个人中心",
        component: () => import('@/views/personal/deployment/index.vue')
    },
    {
        path: "/personal/check/index",
        name: "复核个人中心",
        component: () => import('@/views/personal/review/index.vue')
    },
    {
        path: "/personal/pharmacist/index",
        name: "药师个人中心",
        component: () => import('@/views/personal/pharmacist/index.vue')
    }
]