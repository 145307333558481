import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 使用vuex
const store = new Vuex.Store({
    state: {
        count: 1,
        sendMessage: "", // socket发送信息方法
        retMessage: {}, // 医生或药师返回的信息
        websocket: "", // socket 实例
        onMessage: "",
        isLoginSocket: false,
        waiting_num: 0, // 等待人数
        doctor_info: {}, // 问诊医生的详细信息
        department1: [], // 转接医生数组
        department2: [],
        changeWalletState: 0,
        messageNum: 0,
        isLocation: false
    },
    getters: {
        getStatus(state) {
            return state.count
        },
        getWaitNum(state) {
            return state.waiting_num
        }
    },
    mutations: {
        landChecking1(state) {
            state.count = 2
        },
        landChecking2(state) {
            state.count = 3
        },
        changeRetMessage(state, value) {
            state.retMessage = value;
        },
        setLoginSocket(state, payload) {
            state.isLoginSocket = payload;
        },
        setDoctorInfo(state, payload) {
            state.doctor_info = payload;
        },
        setRetMessage(state, payload) {
            state.retMessage = payload;
        },
        setWaitingNum(state, payload) {
            state.waiting_num = payload;
        },
        setWalletState(state, payload) {
            state.changeWalletState = payload;
        },
        setMessageNum(state, payload) {
            state.messageNum = payload;
        }
    }

})
export default store