export default  [
    {
      path: '/aboutUs',
      name: '/aboutUs',
      component: () => import('@/views/publicbottom/aboutus')
    },
    {
      path: '/service',
      name: '/service',
      component: () => import('@/views/publicbottom/service')
    },
    {
      path: '/contactus',
      name: '/contactus',
      component: () => import('@/views/publicbottom/contactus')
    }
  ]
