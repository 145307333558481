export default [
  {
    path: '/',
    name: '首页',
    component: () => import('@/views/index/index')
  },
  {
    path: '/search',
    name: '搜索',
    component: () => import('@/views/index/search')
  },
  {
    path: '/doctor/index',
    name: '线上问诊',
    component: () => import('@/views/index/doctor/index')
  },
  {
    path: '/doctor/doctorDetails',
    name: '医生详情',
    component: () => import('@/views/index/doctor/doctorDetails')
  },
  {
    path: '/doctor/allComment',
    name: '医生的全部评价',
    component: () => import('@/views/index/doctor/allComment')
  },
  {
    path: '/doctor/wait',
    name: '医生接诊等待详情',
    component: () => import('@/views/index/doctor/wait')
  },
  {
    path: "/onlineView",
    name: "问诊界面",
    component: () => import("@/views/index/wenzhen/main")
  },
  {
    path: '/video',
    name: '视频',
    component: () => import('@/views/index/wenzhen/video')
  },
  {
    path: '/pharmacist/index',
    name: '药师咨询',
    component: () => import('@/views/index/pharmacist/index')
  },
  {
    path: '/pharmacist/pharmDetails',
    name: '药师详情',
    component: () => import('@/views/index/pharmacist/pharmDetails')
  },
  {
    path: '/pharmacist/wait',
    name: '药师接诊等待详情',
    component: () => import('@/views/index/pharmacist/wait')
  },
  {
    path: '/shenfang/index',
    name: '药师审方',
    component: () => import('@/views/index/shenfang/index')
  },
  {
    path: '/shenfang/upimg',
    name: '药师审方上传图片',
    component: () => import('@/views/index/shenfang/upimg')
  },
  {
    path: '/expert/index',
    name: '找专家',
    component: () => import('@/views/index/expert/index')
  },
  {
    path: '/expert/expertDetails',
    name: '找专家详情',
    component: () => import('@/views/index/expert/expertDetails')
  },
  {
    path: '/expert/payOrder',
    name: '专家支付订单页',
    component: () => import('@/views/index/expert/payOrder')
  },
  {
    path: '/expert/wait',
    name: '药师接诊等待详情',
    component: () => import('@/views/index/expert/wait')
  },
  {
    path: '/fast',
    name: '快速购药',
    component: () => import('@/views/index/fast')
  },
  {
    path: '/healthy',
    name: '健康知识',
    component: () => import('@/views/index/healthy')
  },
  {
    path: '/textinfor',
    name: '文章详情',
    component: () => import('@/views/index/textinfor')
  },
  {
    path: '/aboutUs',
    name: '关于我们',
    component: () => import('@/views/index/aboutUs')
  },
  {
    path: '/promotion',
    name: '活动促销',
    component: () => import('@/views/index/promotion')
  },
  {
    path: '/allGoods',
    name: '全部商品',
    component: () => import('@/views/index/allGoods')
  },
  {
    path: '/goodsDetails',
    name: '商品详情',
    component: () => import('@/views/index/goodsDetails')
  }, {
    path: '/goodsOrder',
    name: '确认订单',
    component: () => import('@/views/index/goodsOrder')
  },
  {
    path: '/pay',
    name: '支付',
    component: () => import('@/views/index/pay')
  },
  {
    path: '/agentShop',
    name: 'agentShop',//代理商全部商品
    component: () => import('@/views/index/agentShop')
  },
  
  {
    path: '/user/paymoney',
    name: '保证金',
    component: () => import('@/views/index/user/paymoney')
  },
  
  {
    path: '/user/mysale',
    name: '我的分销',
    component: () => import('@/views/index/user/mysale')
  },
  {
    path: '/user/invitation',
    name: 'invitation',
    component: () => import('@/views/index/user/invitation')
  },
  {
    path: '/payResuly',
    name: '支付结果',
    component: () => import('@/views/index/payResuly')
  },
  {
    path: '/user/index',
    name: '个人主页',
    component: () => import('@/views/index/user/index')
  },
  {
    path: '/user/userData',
    name: '个人信息更改',
    component: () => import('@/views/index/user/userData')
  },
  {
    path: '/user/myExpert',
    name: '我的专家',
    component: () => import('@/views/index/user/myExpert')
  },
  {
    path: '/user/goods',
    name: '我的商品',
    component: () => import('@/views/index/user/goods')
  },
  {
    path: '/user/integral',
    name: '我的积分',
    component: () => import('@/views/index/user/integral/integral')
  },
  {
    path: '/user/wallet',
    name: '我的钱包',
    component: () => import('@/views/index/user/wallet/wallet')
  }, {
    path: '/user/wallet/pay',
    name: '我的钱包',
    component: () => import('@/views/index/user/wallet/pay')
  },{
    path: '/user/allOrder',
    name: '我的订单',
    component: () => import('@/views/index/user/allOrder/allOrder')
  },
  {
    path: '/user/OrderDetails',
    name: '我的订单详情',
    component: () => import('@/views/index/user/allOrder/OrderDetails')
  },
  {
    path: '/user/OrderAssess',
    name: '我的订单评价',
    component: () => import('@/views/index/user/allOrder/OrderAssess')
  }, {
    path: '/user/returnGoods',
    name: '我的订单退换',
    component: () => import('@/views/index/user/allOrder/returnGoods')
  }, {
    path: '/user/address',
    name: '我的地址列表',
    component: () => import('@/views/index/user/address')
  },
  {
    path: '/user/invoice',
    name: '我的发票列表',
    component: () => import('@/views/index/user/invoice')
  },
  {
    path: '/user/apply',
    name: '申请入驻',
    component: () => import('@/views/index/user/apply')
  },
  {
    path: '/user/member',
    name: '电子会员卡',
    component: () => import('@/views/index/user/member')
  },
  {
    path: '/user/problem',
    name: '常见问题',
    component: () => import('@/views/index/user/problem')
  }, {
    path: '/user/feedBack',
    name: '功能反馈',
    component: () => import('@/views/index/user/feedBack')
  }, {
    path: '/user/myDoctor',
    name: '我的医生',
    component: () => import('@/views/index/user/myDoctor')
  },
  {
    path: '/user/coupon',
    name: '我的优惠券',
    component: () => import('@/views/index/user/coupon')
  }, {
    path: '/user/prescribe',
    name: '我的药方',
    component: () => import('@/views/index/user/prescribe')
  },
  {
    path: '/user/archives',
    name: '我的档案',
    component: () => import('@/views/index/user/archives')
  },
  {
    path: '/user/Interrogation',
    name: '我的问诊',
    component: () => import('@/views/index/user/wenzhen/mywenzhen')
  },
  {
    path: '/user/news',
    name: '我的消息',
    component: () => import('@/views/index/user/news')
  },
  {
    path: '/shopAddress',
    name: '药店地址',
    component: () => import('@/views/index/shopAddress')
  },
  {
    path: '/cart',
    name: '购物车',
    component: () => import('@/views/index/cart')
  },
  {
    path: '/cartOrder',
    name: '购物车确认订单',
    component: () => import('@/views/index/cartOrder')
  },
  {
    path: "/user/allCoupon",
    name: "全部优惠券",
    component: () => import("@/views/index/user/allCoupon")
  },
  {
    path: "/user/myDrug",
    name: "我的用药",
    component: () => import("@/views/index/user/myPharmacy")
  }
]