export default [
    {
      path: '/land',
      name: '/land',
      component: () => import('@/views/land/land')
    },
    {
      path: '/logon',
      name: '/logon',
      component: () => import('@/views/land/logon')
    } ,
    {
      path: '/forgetPassword',
      name: '/forgetPassword',
      component: () => import('@/views/land/forgetPassword')
    }
  ]