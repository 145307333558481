<template>
  <!-- 建立这个组件的目的就是初始化socket，保证无论页面怎么切换socket都不会关闭 -->
  <div v-if="false" class="socket"></div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      path: "wss://apponline.sdlzyy.cn/wss",
      socket: null,
      websock: null
    };
  },
  created() {
    let token = localStorage.getItem("lzyyToken");
    this.$token = token;
    if (!token) return;
    if (!this.$store.state.isLoginSocket) {
		
      this.initWebSocket();
    } else {
      this.websocketsend = this.$store.state.sendMessage;
      this.$store.state.websocket.onmessage = this.websocketonmessage;
    }
  },
  methods: {
    initWebSocket() {
      //初始化weosocket
      // 1. 连接
      const wsuri = "wss://hlwyaodian.jzbwlkj.com/wss";
      this.websock = new WebSocket(wsuri);

      this.websock.onopen = this.websocketonopen;
      // 回调
      this.websock.onmessage = this.websocketonmessage;
      // 报错
      this.websock.onerror = this.websocketonerror;
      // 断开
      this.websock.onclose = this.websocketclose;
      // 心跳
      setInterval(() => {
        this.websocketsend({
          method: "ping"
        });
      }, 3000);
      // 把发送信息函数放到仓库，全局共享
      this.$store.state.sendMessage = this.websocketsend;
      this.$store.state.isLoginSocket = true;
      this.$store.state.websocket = this.websock;
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      this.websocketsend();
    },
    websocketonerror() {
      //连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e) {
      //数据接收
      const redata = JSON.parse(e.data);
      // 处理消息
      console.log(redata);

      switch (redata.method) {
        // 登陆用户
        case "sys":
          this.userlogin();
          break;
        case "sys_message":
          this.changeWaitNum(redata.result);
          break;
        case "doctor_connect":
          this.changeOnlineView(redata.result);
          break;
        case "chat_in":
          this.getDoctorInfo(redata.result);
          break;
        case "chat_close":
          this.getDoctorInfo(redata.result);
          break;
        case "chat_message":
          this.chatMessage(redata.result);
          break;
        case "get_pay_status":
          this.expertInquirtView(redata.result);
          break;
      }
    },
    changeOnlineView(result) {
      if (result.chat_status == 5) {
        this.$message({
          type: "error",
          message: `抱歉，你的问诊被拒绝，原因是${result.remark}。请退出当前等待。`
        });
        return;
      }
      if (result.chat_type == 0) {
        this.$router.push(
          `/onlineView?inquiry_doctor_id=${result.inquiry_doctor_id}&inquiry_id=${result.inquiry_id}&chat_type=${result.chat_type}`
        );
      } else {
        this.$router.push(
          `/video?inquiry_doctor_id=${result.inquiry_doctor_id}&inquiry_id=${result.inquiry_id}&chat_type=${result.chat_type}`
        )
      }
    },
    userlogin() {
      let actions = {
        method: "login",
        token: this.$token
      };
      this.websocketsend(JSON.stringify(actions));
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      alert("断开连接", e);
    },
    getDoctorInfo(result) {
      if (result.user_info.cert_type != 0) {
        this.setDoctorInfo(result.user_info);
        this.setRetMessage(result);
      }
    },
    chatMessage(result) {
      // 接收到对方发送的message信息
      this.setRetMessage(result);
    },
    changeWaitNum(result) {
      if (result.waiting_info) {
        // waiting_info是一个数组
        this.setWaitingNum(result.waiting_info[0].waiting_num);
      }
      this.setMessageNum(result.message_num);
    },
    expertInquirtView(result) {
      // 进入专家问诊界面
      if (result.pay_status == 1) {
        let {
          id,
          uid,
          inquiry_doctor_id,
          inquiry_id
        } = this.$router.history.current.query;
        this.$router.push({
          path: `/doctor/wait?id=${id}&uid=${uid}&inquiry_doctor_id=${inquiry_doctor_id}&inquiry_id=${inquiry_id}`
        });
      }
    },
    ...mapMutations([
      "setDoctorInfo",
      "setRetMessage",
      "setWaitingNum",
      "setMessageNum"
    ])
  }
};
</script>