<template>
	<div id="app">
		<router-view />
		<socket v-if="isRenderSocket" />
	</div>
</template>

<script>
	import {
		usergetinfo
	} from "@/request/user";
	import socket from "./views/index/socket"
	export default {
		components: {
			socket
		},
		data() {
			return {
				isRenderSocket: false
			}
		},
		created() {
			window.alert = function() {
				return false;
			};
		},
		mounted() {
			if (this.$token) {
				usergetinfo({
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						if (res.data.cert_type == 0) {
							this.isRenderSocket = true
						}
					}
				});
			}
		},

	};
</script>

<style lang="less">
	@import "./assets/less/inite";

	@import "./assets/less/header";

	@import "./assets/less/chat";

	* {
		margin: 0;
		padding: 0;
		// font-size: 14px;
	}
</style>
