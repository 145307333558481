import { post, get } from './http'

// 用户信息 
export const usergetinfo = data => post('api/user/getInfo', data)

// 修改用户信息
export const updateinfo = data => post('api/user/updateInfo', data)

//  修改密码
export const modifypassword = data => post('api/user/modifyPassword', data)


//  修改 手机号
export const modifyMobile = data => post('api/user/modifyMobile', data)

//  获取自己申请医生状态
export const getApplyInfo = data => post('api/doctor/getApplyInfo', data)

//  申请医生
export const apply_yisheng = data => post('api/doctor/apply', data)

//  获取科室所有分类
export const keshi_list = data => post('api/doctor/getAllCategoryList', data)

//  获取 所有 地区列表
export const getCityData = data => post('api/public/getCityData', data)

//  获取 下级地区列表
export const getAreaLista = data => post('api/public/getAreaList', data)

// 获取收货地址列表
export const user_address_List = data => post('api/user_address/getList', data)

// 添加收货地址列表
export const user_address_Add = data => post('api/user_address/add', data)

// 删除地址
export const user_address_del = data => post('api/user_address/del', data)

// 修改地址
export const updataAddress = data => post("/api/user_address/edit", data);

//   获取积分兑换商品列表
export const score_shop = data => post('api/score_shop/index', data)

//  开始兑换
export const buyScore = data => post("api/score_order/buyScore", data)

//  获取兑换记录
export const recordOrder = data => post("api/score_order/index", data)

// 开始抽奖
export const ringStart = data => post("api/ring/ringStart", data)

// 查看中奖记录
export const getMyRing = data => get("api/ring_order/getMyRing", data)

// 获取抽奖的奖品列表
export const ringList = data => get("api/ring/index", data)

//  获取关注收藏 统计
export const getCount = data => post('api/user_fav/getCount', data)

//  获取 关注收藏 列表
export const getAttentionList = data => post('api/user_fav/getAttentionList', data)

//  删除收藏
export const delFav = data => post("/api/user_fav/del", data)

//  添加/删除关注
export const addFav = data => post("/api/doctor/addFav", data)

//  用户银行卡列表
export const getListCard = data => post('api/user_bank/getList', data)

//  添加用户银行卡 
export const add = data => post('api/user_bank/add', data)

//  获取用户银行卡详情
export const getInfos = data => post('api/user_bank/getInfo', data)

//  删除用户银行卡
export const deletes = data => post('api/user_bank/delete', data)

//  绑定支付宝
export const bindAlipay = data => post('api/user/bindAlipay', data)

//  绑定微信
export const bindWxpay = data => post('api/user/bindWxpay', data)

//  修改申请发票
export const adds = data => post('api/user_invoice/add', data)

//  获取发票信息
export const fapiao_list = data => post('api/user_invoice/getInfo', data)

//   添加 反馈
export const feedback = data => post('api/feedback/add', data)

//  添加/修改 健康 档案体检报告
export const add_dangan = data => post('api/user_patient/add', data)

//   获取报告列表
export const dangan_getList = data => post('api/user_patient/getList', data)

//   获取报告列表
export const del_dangan = data => post('api/user_patient/del', data)

//   获取 取消息首页
export const message = data => post('api/message/index', data)

//    获取消息列表
export const message_list = data => post('api/message/getList', data)

//    . 获取新消息
export const  getNewMessageNum = data => post('api/message/getNewMessageNum', data)

//    获取问诊列表
export const getInquiryList = data => post("/api/inquiry/getList", data)

//   添加医生评论
export const addInquiryComment = data => post("/api/inquiry/addComment", data);

//   获取医生评论
export const getCommentList = data => get("/api/doctor/getCommentList", data)

//  发送复诊信息
export const sendFurtherInfo = data => post("/api/inquiry/setOrder", data)

//   获取我的处方
export const getPrescriptionList = data => get("/api/prescription/getList", data)

//   获取全部优惠券
export const getAllCoupons = data => get("api/Coupon/all_coupon", data)

//  领取券
export const getCoupons = data => get("api/Coupon/get_coupon", data)

//   查看我的优惠券
export const getMyCoupons = data => get("api/Coupon/my_coupon", data)

//   获取常见问题
export const getQuestionList = data => get("/api/question/getList", data)

//   获取文章分类
export const getCategoryList = data => get("/api/article/getCategoryList");

//   获取文章列表
export const getArticleList = data => get("/api/article/getList", data)

//   获取医师自己审核的药房
export const getDoctorAuditList = data => get("/api/prescription/getDoctorAuditList", data)

//   专家支付
export const inquiryPay = data => post("/api/pay/inquiry", data)

//   获取订单列表
export const getMyOrder = data => get("api/Goods_order/getMyOrder", data)

//   获取与医生的聊天记录
export const getChatList = data => get("/api/inquiry/getChatList", data)

// 账户充值
export const recharge = data => get("/api/recharge/index", data)

// 处方单详情
export const getInfo = data => get("/api/prescription/getInfo", data)

// 获取roomToken
export const getRoomToken = data => get("/api/qiniu_rtc/getRoomToken", data)