import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter()
export function open(url) {
    let { href } = router.resolve({
        path: `/${url}`
    })
    window.open(href)
}