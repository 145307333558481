import Vue from 'vue'


import VueRouter from 'vue-router'
// 路由
import land from './land' ;
import client from './client' ;
import homesPage from './homesPage'
import personalCenter from './personalCenter'
import publicbottom from './publicbottom' ;

Vue.use(VueRouter)



const router = new VueRouter({
  routes:[
    ...land,
    ...client,
    ...publicbottom,
    ...homesPage,
    ...personalCenter,
  ]
 
})

export default router
