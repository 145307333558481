// 药师端路由配置

export default [
  {
    path: '/home/pharmacist/index',
    name: '药师端首页',
    component: () => import('@/views/homesPage/pharmacist/index'),
  },
  {
    path: '/home/expert/index',
    name: '专家端首页',
    component: () => import('@/views/homesPage/expert/index'),
  },
  {
    path: '/home/doctor/index',
    name: '医生端首页',
    component: () => import('@/views/homesPage/doctor/index'),
  },
  {
    path: '/home/deployment/index',
    name: '调配端首页',
    component: () => import('@/views/homesPage/deployment/index'),
  }
  ,
  {
    path: '/home/check/index',
    name: '复核端首页',
    component: () => import('@/views/homesPage/check/index'),
  }
]