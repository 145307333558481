/**
 * @Author: zhang
 * @Date:   2021-01-25 13:12:03
 * @Last Modified by:   zhang
 * @Last Modified time: 2021-04-08 16:09:54
 */
import axios from 'axios'; // 引入axios
import QS from 'qs';


//引入vue
import Vue from 'vue';
//新创建一个vue实例
let v = new Vue();

// import store from '@/store/index';

// token       登录token信息
// page        分页

// 环境的切换
if (process.env.NODE_ENV == 'development') { 
    // axios.defaults.baseURL = 'https://lianzhong.jzbwlkj.com';
	 axios.defaults.baseURL = 'https://www.fu-nuo.com';
}
else if (process.env.NODE_ENV == 'debug') {
    axios.defaults.baseURL = 'https://www.baidu.com';
}
else if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = 'https://www.fu-nuo.com';
    // axios.defaults.baseURL = 'https://lianzhong.jzbwlkj.com';
}

axios.defaults.timeout = 10000;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

// 请求拦截器]
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断vuex中是否存在token        
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
        // const token = store.state.token;        
        // token && (config.headers.Authorization = token);        
        return config;
    },
    error => {
        return Promise.error(error);
    })
// 响应拦截器
axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
        // 否则的话抛出错误
        if (response.status === 200) {
            // if (response.data.code === 0 && response.data.msg !== '支付中') {
                // v.$message.error(response.data.msg);
            // }
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },

)

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}

export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, QS.stringify(params))
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

